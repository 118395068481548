"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    power_lucien: "This power does nothing",
    power_phone: "+30% on your gold lose and gain<br/>does not include the purchase of powers",
    power_fox: "Give you a random power",
    power_deserterJack: "You start with one less random piece.",
    power_monkeys: "Your two pieces with the least points disappear.",
    power_mirror: "The points on the bard are in reverse order <br/> ( only for you).",
    power_steve: "You play first",
    power_pact: "At the beginning of the round, you and your opponent add a piece of your choice to the board.",
    power_watch: "Your maximum points for knocking increase by 10.<br/>Bonus of 10 gold when you knock",
    power_chimist: "You can align the pieces diagonally.<br/>You will get the 4 biggest unaligned pieces from the enemy.",
    power_fog: "Your opponent does not know the other powers you choose.",
    power_curse: "Your opponent is forced to choose Lucien<br/>He does not spend the gold of his initial choice",
    power_final: "You can knock with no minimum points.",
    power_karen: "The pieces are redistributed, for everyone.",
    power_eye: "You see all the opponent's pieces (after war pact) <br/>Your points are doubled.",
    power_unknow: "whoever read this love adolph hitler",
    draw: "Draw",
    win: "You won",
    he_capitulate: "He capitulated",
    lost: "You lost",
    you_capitulate: "You capitulated",
    capitulate: "Capitulate",
    back_lobby: "Back to lobby",
    revenge: "Revenge",
    playbot: "Play against computer",
    you_challenge: "You challenged",
    he_challenge: "challenged you !",
    accept: "accept",
    decline: "decline",
    cancel: "cancel",
    online: "online",
    ingame: "in game",
    challenging: "challenging",
    greenPiece: "Green piece",
    randomPiece: "Random piece",
    nextRound: "Next round",
    name: "username",
    password: "password",
    login: "login",
    newAccount: "new account",
    userEmpty: "username is empty",
    userAlphaNum: "username must contain only letter and num",
    userTooLong: "username is too long",
    passEmpty: "password field is empty",
    youFull: "You made FULL and won",
    heFull: "The Scum made FULL and won",
    youKnockWith: "You knocked with",
    heKnockWith: "The scum knocked with",
    youAreWinning: "You won",
    heIsWinning: "He won",
    youKnock: "You knocked",
    heKnock: "The scum Knocked",
    with: "with",
    heCounter: "The scum counter knocked and won",
    youCounter: "You counter knocked and won",
    youPick: "Pick an empty case",
    hePick: "Is is scum turn to pick an empty case",
    pickPower: "Pick a power",
    youPlay: "Jouer play",
    first: "first",
    second: "second",
    waitPower: "scum is picking power",
    waitPower2: "he is taking so much time...",
    infoPickGreen: "Pick the green piece",
    infoPickRandom: "or pick random piece",
    opTurn: "It is scum turn to play",
    discardBlue: "Discard a blue piece",
    orKnockFor: "..Or knock for",
    opTurnDiscard1: "it is scum turn",
    opTurnDiscard2: "to discard a piece",
    rules: "Rule of the game",
};
