"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BOT_ID = exports.MAX_POWER_NUMBER = exports.START_GOLD = exports.SANCTION_POINTS = exports.FULL_POINTS = exports.MIN_TO_KNOCK = exports.INITIAL_CARD_AMOUNT = exports.BOARD_SIZE = void 0;
exports.BOARD_SIZE = 8;
exports.INITIAL_CARD_AMOUNT = 12;
exports.MIN_TO_KNOCK = 30;
exports.FULL_POINTS = 30;
exports.SANCTION_POINTS = 30;
exports.START_GOLD = 250;
exports.MAX_POWER_NUMBER = 3;
exports.BOT_ID = "aaaaaaaaaaaaaaaaaaaaaaaa";
