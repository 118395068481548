"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    power_lucien: "Ce pouvoir ne fait rien.",
    power_phone: "+30% sur vos gains et pertes en gold.<br/>N'inclus pas l'achat de pouvoirs.",
    power_fox: "Vous donne un pouvoir aleatoire",
    power_deserterJack: "Vous debutez avec une piece aléatoire en moins.",
    power_monkeys: "Vos deux pieces avec le moins de points disparaissent.",
    power_mirror: "Les points sur le board sont inversé <br/> ( que pour vous).",
    power_steve: "Vous commencez en premier.",
    power_pact: "Au debut du round, vous et votre adversaire ajoutez une piece de votre choix sur le board.",
    power_watch: "Vos points maximum pour knocker augmentent de 10.<br/>Bonus de knock de 10 gold.",
    power_chimist: "Vous pouvez aligner les pieces en diagonal.<br/>Vous recuperez les 4 plus grosses pieces non aligné de l'ennemi.",
    power_fog: "Votre adversaire ne connnait pas les autres pouvoirs que vous choisissez.",
    power_curse: "Votre adversaire est forcé de choisir Lucien<br/>Il ne depense pas les gold de son choix initial",
    power_final: "Vous pouvez knocker sans minimum de points.",
    power_karen: "Les pieces sont redistribués, pour tout le monde.",
    power_eye: "Vous voyez toute les piece de l'adversaire (apres war pact) <br/>Vos points sont doublés.",
    power_unknow: "whoever read this love adolph hitler",
    draw: "Egalité",
    win: "Vous avez gagné",
    he_capitulate: "Il a capitulé",
    lost: "Vous avez perdu",
    you_capitulate: "vous avez capitulé",
    capitulate: "Capituler",
    back_lobby: "Retour au lobby",
    revenge: "Revanche",
    playbot: "Jouer contre l'ordi",
    you_challenge: "Vous challengez",
    he_challenge: "vous challenge !",
    accept: "accepter",
    decline: "refuser",
    cancel: "annuler",
    online: "en ligne",
    ingame: "en jeu",
    challenging: "en challenge",
    greenPiece: "Piece verte",
    randomPiece: "Piece aléatoire",
    nextRound: "Round suivant",
    name: "nom",
    password: "Mot de passe",
    login: "se connecter",
    newAccount: "Nouveau compte",
    userEmpty: "le champ nom est vide",
    userAlphaNum: "Le nom doit contenir que des lettres et chiffres",
    userTooLong: "Le nom est trop long",
    passEmpty: "le mot de passe est vide",
    youFull: "Vous rentrez le FULL et gagnez",
    heFull: "Le fumier rentre le FULL et gagne",
    youKnockWith: "Vous knockez avec",
    heKnockWith: "Le Fumier knock avec",
    youAreWinning: "vous gagnez",
    heIsWinning: "il gagne",
    youKnock: "Vous knockez",
    heKnock: "Le fumier knock",
    with: "avec",
    heCounter: "Le fumier contre knock et gagne",
    youCounter: "vous contre knockez et gagnez",
    youPick: "Choisissez une case vide",
    hePick: "C'est au fumier de choisir une case vide",
    pickPower: "Choisissez un pouvoir",
    youPlay: "Vous jouez en",
    first: "premier",
    second: "deuxieme",
    waitPower: "On attend le fumier qu'il choisisse son pouvoir",
    waitPower2: "Il prend un temps fou",
    infoPickGreen: "Choisissez la piece verte",
    infoPickRandom: "Ou choisissez une piece aleatoire",
    opTurn: "C'est au tour du fumier de jouer",
    discardBlue: "Defaussez vous d'une piece bleu",
    orKnockFor: "...ou knockez pour",
    opTurnDiscard1: "C'est au tour du fumier",
    opTurnDiscard2: "de se debarasser d'une piece",
    rules: "Règle du jeu",
};
